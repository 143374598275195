import React from 'react'
import Section from '../../Section'
import SearchInput from '../SearchInput'

import {headingSection} from './faqsearchsection.module.scss'

interface Props {
  searchText: string
  onChange: Function
}

const FAQSearchSection: React.FC<Props> = (props) => {
  return (
    <Section id="search" className={headingSection}>
      <h1>Frequently asked questions</h1>
      <p>Browse the list or type your question in the search box.</p>
      <SearchInput {...props} />
    </Section>
  )
}

export default FAQSearchSection
