import React from 'react'
import {
  searchContainer,
  searchBox,
  searchInput,
  searchIcon,
} from './searchinput.module.scss'

interface Props {
  searchText: string
  onChange: Function
}

const SearchInput: React.FC<Props> = ({searchText, onChange}) => {
  return (
    <div className={searchContainer}>
      <div className={searchBox}>
        <input
          type="text"
          name="search-box"
          value={searchText}
          onChange={(e) => onChange(e.currentTarget.value)}
          placeholder="Search"
          className={searchInput}
        />
        <div className={searchIcon}>&nbsp;</div>
      </div>
    </div>
  )
}

export default SearchInput
