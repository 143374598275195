import React from 'react'
import type {NavLink} from '../../components/Layout'
import {Header, Footer} from '../../components/Layout'
import FAQ from '../../components/FAQ'

import SEO from '../../components/SEO'
import {URL} from '../../constants'

const pageCertificate = {
  jobCode: 'GB-HPV-00209',
  dateOfPrep: 'May 2023',
}

const NAV_LINKS: NavLink[] = [
  {
    text: 'Information for parents',
    url: URL.HOME,
  },
  {
    text: 'Information for teenagers',
    url: URL.TEENAGERS,
  },
  {text: 'FAQs', url: URL.FAQS},
]

const Faqs = () => {
  return (
    <>
      <SEO
        path="faqs"
        title="Frequently asked questions - MSD"
        description="Find answers to frequently asked questions about HPV, HPV cancers and vaccination in boys and girls aged 12-13 years."
        keywords="Frequently asked questions, HPV, human papillomavirus, HPV vaccination, HPV cancers, HPV types, NHS vaccination schedule, HPV vaccine, 12-13 years, boy, girl, virus, viruses, HPV 16, HPV 18, dose, cervical cancer, anal cancer, genital warts, injection, herd immunity, protection, child, consent, consent form, school, year 8"
      />
      <Header navLinks={NAV_LINKS} />
      <main className="content overflow-hidden">
        <FAQ />
      </main>
      <Footer {...pageCertificate} />
    </>
  )
}

export default Faqs
