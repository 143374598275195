import React from 'react'
import {Container} from 'react-bootstrap'
import {section, sectionShadow, containerGrid} from './Section.module.scss'

interface Props {
  id: string
  children: React.ReactNode
  overlayImage?: React.ReactNode
  className?: string
  shadowOverlay?: boolean
}

const Section = ({
  id,
  children,
  className = '',
  overlayImage = null,
  shadowOverlay = false,
}: Props) => {
  return (
    <section
      id={id}
      className={`${section} ${className} ${
        shadowOverlay ? sectionShadow : ''
      }`}
    >
      {overlayImage || null}
      <Container className={`${shadowOverlay ? containerGrid : ''}`}>
        {children}
      </Container>
    </section>
  )
}

export default Section
