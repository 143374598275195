import React, {useState} from 'react'
import {AnchorLink} from 'gatsby-plugin-anchor-links'
import {Accordion, Button} from 'react-bootstrap'
import Feedback from '../Feedback'

import * as styles from './Question.module.scss'

import {Data, Answer} from '../types'

interface Props {
  data: Data
  searchText: string
  openExternalLinkPopUp: Function
  activeKey: string
}

const Question: React.FC<Props> = ({
  data,
  searchText,
  openExternalLinkPopUp,
  activeKey,
}) => {
  const {id, question, answer} = data

  // Function to highight search text
  const getHighlightedText = (text: string) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'))

    if (searchText.length < 1) {
      return text
    }

    return parts
      .map((part) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? `<span class="bg-warning">${part}</span>`
          : `<span>${part}</span>`,
      )
      .join('')
  }

  const getAnswerSectionBasedOnTypes = (item: Answer, index: number) => {
    switch (item.type) {
      case 'text':
        return generateAnswerText(item, index)
      case 'list':
        return generateAnswerList(item, index)
      case 'text-inline':
        return generateInlineText(item, index)
      case 'external-link':
        return generateExternalLink(item, index)
      case 'anchor-link':
        return generateAnchorLink(item, index)
      case 'button':
        return generateMoreButton(item, index)
      default:
        return null
    }
  }

  /*  Function to generate Answer section in FAQ's
      Answers are of different types (eg text, list), Will push elements into an array based on types
      Returns an array of answer html elements to be rendered */
  const generateAnswerSection = (answerData: Answer[]) => {
    const answerToDisplay: Array<React.ReactNode> = []
    answerData.forEach((item: Answer, i: number) => {
      answerToDisplay.push(getAnswerSectionBasedOnTypes(item, i))
    })
    return answerToDisplay
  }

  /* Function to generate answer if the type is text
     Returns an html element with title(if present) and text content */
  const generateAnswerText = ({title, content}: Answer, index: number) => (
    <React.Fragment key={index}>
      {title && (
        <h2
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(title),
          }}
        />
      )}
      {content && (
        <p
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(content),
          }}
        />
      )}
    </React.Fragment>
  )

  /* Function to generate answer if the type is list
     Returns an html <ul> element from list in the data(answers) */
  const generateAnswerList = ({items}: Answer, index: number) => (
    <ul key={index}>
      {items &&
        items.map((listItem: string, j: number) => (
          <li
            key={j}
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(listItem),
            }}
          />
        ))}
    </ul>
  )

  const generateInlineText = ({content}: Answer, index: number) => (
    <React.Fragment key={index}>
      {content && (
        <p
          className="d-inline"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(content),
          }}
        />
      )}
    </React.Fragment>
  )

  const generateExternalLink = ({content}: Answer, index: number) => (
    <React.Fragment key={index}>
      {content && (
        <a
          href={content}
          onClick={(e) => {
            e.preventDefault()
            openExternalLinkPopUp(true)
          }}
          className="text-secondary"
        >
          {content}
        </a>
      )}
    </React.Fragment>
  )

  const generateAnchorLink = ({link, content}: Answer, index: number) => (
    <React.Fragment key={index}>
      {content && (
        <a href={link} className="text-secondary">
          {content}
        </a>
      )}
    </React.Fragment>
  )

  const generateMoreButton = ({link, content}: Answer, index: number) => (
    <React.Fragment key={index}>
      {content && (
        <AnchorLink to={link || ''} stripHash>
          <Button variant="secondary" className="mb-3">
            {content}
          </Button>
        </AnchorLink>
      )}
    </React.Fragment>
  )

  return (
    <Accordion.Item id={id} eventKey={activeKey}>
      <Accordion.Header className={styles.header}>
        <span
          className={styles.title}
          dangerouslySetInnerHTML={{__html: getHighlightedText(question)}}
        />
      </Accordion.Header>

      <Accordion.Body className={styles.body}>
        <>
          {generateAnswerSection(answer)}
          <Feedback question={question} />
        </>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Question
