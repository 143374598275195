import React, {useState, useEffect} from 'react'
import {Accordion} from 'react-bootstrap'
import Question from './Question'
import Section from '../Section'
import FAQSearchSection from './FAQSearchSection'
import ExternalLinkPopUp from '../ExternalLinkPopUp'

import faqData from './data.json'
import {Data} from './types'
import {URL} from '../../constants'

// Return true if question or answer countains search keywords
const search = (data: any, keywords: string): boolean => {
  for (const key in data) {
    if ({}.hasOwnProperty.call(data, key)) {
      const value = data[key]
      if (
        typeof value === 'string' &&
        value.toLowerCase().indexOf(keywords.toLowerCase()) > -1
      )
        return true

      if (typeof value === 'object') {
        const result = search(value, keywords)
        if (result) return true
      }
    }
  }
  return false
}

const FAQ = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [showExternalLinkPopUp, setShowExternalLinkPopUp] = useState(false)

  /* Show question body when id of question is in hash of the url */
  useEffect(() => {
    const [, id] = window.location.hash.split('#')
    document.querySelector<HTMLButtonElement>(`#${id} button`)?.click()
  }, [])

  const filteredData: Data[] = faqData.filter((data: Data) =>
    search(data, searchText),
  )

  return (
    <>
      <FAQSearchSection searchText={searchText} onChange={setSearchText} />

      <Section id="answers" className="py-5">
        <Accordion alwaysOpen flush>
          {filteredData.map((data) => (
            <Question
              data={data}
              searchText={searchText}
              key={data.id}
              activeKey={data.id}
              openExternalLinkPopUp={setShowExternalLinkPopUp}
            />
          ))}
        </Accordion>
      </Section>

      <ExternalLinkPopUp
        show={showExternalLinkPopUp}
        handleClose={() => setShowExternalLinkPopUp(false)}
        link={URL.YELLOW_CARD_SCHEME}
      />
    </>
  )
}

export default FAQ
